<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="개인정보처리방침" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<main class="page-container" id="page-privacy">
					<header class="auth-header">
						<h2 class="title">개인정보처리방침</h2>
					</header>
					<div class="page-body">
						<div class="tos-content">
							<!-- s: 여기서부터 시작 -->
							<p class="pt-2"><strong>중소기업유통센터가 처리하는 모든 개인정보는 관련 법령에 근거하거나 정보주체의 동의에 의하여 처리되고 있습니다.</strong></p>
							<p class="pt-2">중소기업유통센터는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 정보주체의 고충을 원활하게 처리할 수 있 도록 다음과 같은 개인정보처리방침을 수립하고 있으며, 개인정보처리방침을 개정 하는 경우 웹사이트 등을 통하여 공지할 것입니다.</p>

							<div class="section-policies">
								<h3 class="section-policies-title">주요개인정보처리 표시(라벨링)</h3>
								<div class="privacy-legend">
									<ul class="privacy-legend-list">
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy1-1"></i>
												<em>성명, 생년월일, 전화번호, 주소 등</em>
												<span class="text">일반개인정보</span>
											</div>
										</li>
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-2"></i>
												<em>중소벤처·소상공인의 판로지원 사업관리 등 </em>
												<span class="text">개인정보처리목적</span>
											</div>
										</li>
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy3-2"></i>
												<em> 제1조 개인정보의 처리목적, 처리 및 보유기간, 항목 참조 </em>
												<span class="text">개인정보의 보유기간</span>
											</div>
										</li>
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-6"></i>
												<em> 법령에 따른 개인정보 보유·이용기간 또는 수집시 동의받은 개인정보 보유·이용기간 </em>
												<span class="text">개인정보의 제공</span>
											</div>
										</li>
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy3-1"></i>
												<em> 시스템유지관리 등 </em>
												<span class="text">처리위탁</span>
											</div>
										</li>
										<li>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-5"></i>
												<em>
													(담당부서) 정보보안기획팀<br />
													(연락처) 02-6678-9412, 9416
												</em>
												<span class="text">고충처리부서</span>
											</div>
										</li>
									</ul>
								</div>
								<p class="section-policies-desc">※ 기호에 마우스 커서를 대시면 세부사항을 확인할 수 있으며, 자세한 내용은 아래의 개인정보 처리방침을 확인하시기 바랍니다.</p>
							</div>

							<div class="section-policies">
								<h3 class="section-policies-title">목차</h3>
								<div class="privacy-index">
									<ul class="privacy-index-list">
										<li>
											<button @click="scrollTo('p1')" class="privacy-index-link">제1조 개인정보의 처리목적, 처리 및 보유기간, 항목</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-1"></i>
												<em>개인정보처리항목</em>
											</div>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-2"></i>
												<em>중소벤처·소상공인의 판로지원 사업관리 등</em>
											</div>
											<div class="tooltip-item">
												<i class="icon-privacy privacy3-2"></i>
												<em>개인정보의 보유기간</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p2')" class="privacy-index-link">제2조 개인정보파일 등록현황</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-1"></i>
												<em>개인정보처리항목</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p3')" class="privacy-index-link">제3조 개인정보 제3자 제공</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-6"></i>
												<em>제3자 제공</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p4')" class="privacy-index-link">제4조 개인정보처리의 위탁</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy3-1"></i>
												<em>처리위탁</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p5')" class="privacy-index-link">제5조 개인정보의 파기절차 및 방법</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy3-3"></i>
												<em>파기</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p6')" class="privacy-index-link">제6조 정보주체와 법정대리인의 권리·의무 및 행사방법</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-1"></i>
												<em>정보주체의 권리·의무</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p7')" class="privacy-index-link">제7조 개인정보의 안전성 확보조치</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-2"></i>
												<em>안전성확보조치</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p8')" class="privacy-index-link">제8조 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-4"></i>
												<em>자동화 수집</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p9')" class="privacy-index-link">제9조 추가적인 이용·제공 관련 판단기준</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-3"></i>
												<em>추가적 이용</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p10')" class="privacy-index-link">제10조 가명정보 처리에 관한 사항</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy2-5"></i>
												<em>가명정보 처리</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p11')" class="privacy-index-link">제11조 개인정보 보호책임자 및 고충사항을처리하는 부서</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-4"></i>
												<em>개인정보 보호책임자</em>
											</div>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-5"></i>
												<em>
													고충처리부서<br />
													(담당부서) 정보보안기획팀<br />
													(연락처) 02-6678-9412
												</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p12')" class="privacy-index-link">제12조 개인정보의 열람청구를 접수·처리하는 부서</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-5"></i>
												<em>
													고충처리부서<br />
													(담당부서) 정보보안기획팀<br />
													(연락처) 02-6678-9412
												</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p13')" class="privacy-index-link">제13조 개인정보의 권익침해에 대한 구제방법</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-6"></i>
												<em>권익침해 구제</em>
											</div>
										</li>
										<li>
											<button @click="scrollTo('p14')" class="privacy-index-link">제14조 개인정보처리방침의 변경</button>
											<div class="tooltip-item">
												<i class="icon-privacy privacy4-3"></i>
												<em>처리방침 변경</em>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<!-- s: 제1조 -->
							<div class="section-policies" ref="p1">
								<h3 class="section-policies-title">
									제1조 개인정보의 처리목적, 처리 및 보유기간, 항목
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-1"></i>
										<em>개인정보처리항목</em>
									</div>
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-2"></i>
										<em>중소벤처·소상공인의 판로지원 사업관리 등</em>
									</div>
									<div class="tooltip-item">
										<i class="icon-privacy privacy3-2"></i>
										<em>개인정보의 보유기간</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										중소기업유통센터는 대국민 서비스 제공 및 민원처리, 소관 업무 수행 등의 목적으로 필요한 범위에서 최소한으로 개인정보를 수집하고 있으며, 처리하고 있는 개인정보는 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
										<ol class="number-list">
											<li>
												중소기업유통센터가 처리 및 보유하고 있는 개인정보파일의 처리목적, 항목, 보유기간 등은 다음과 같습니다.
												<div class="tos-table" id="tos-table-01">
													<table>
														<caption>
															순번, 개인정보파일명, 운영근거, 처리목적, 개인정보의 처리 항목, 처리 및 보유기간 항목의 개인정보 파일 관리 목록
														</caption>
														<colgroup>
															<col style="width: 5%" />
															<col style="width: 15%" />
															<col style="width: 15%" />
															<col style="width: 20%" />
															<col />
															<col style="width: 15%" />
														</colgroup>
														<thead>
															<tr>
																<th scope="col">순번</th>
																<th scope="col">개인정보파일명</th>
																<th scope="col">운영근거</th>
																<th scope="col">처리목적</th>
																<th scope="col">개인정보의 처리 항목</th>
																<th scope="col">처리 및 보유기간</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>1</td>
																<td>2024년 동행축제 ‘출석체크’ 이벤트 응모</td>
																<td>정보주체의 동의</td>
																<td>동행축제 출석체크 이벤트 응모, 추첨, 본인확인, 문의, 안내사항 전달, 조사 및 통계, 성과 관리 등</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 생년월일, 휴대전화번호, 연락처 인증
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>2</td>
																<td>2024년 동행축제 ‘출석체크’ 이벤트 당첨</td>
																<td>
																	1. 개인정보 보호법 제24조, 24조의2,<br />
																	2. 국세기본법 시행령 제68조제3항,<br />
																	3. 정보주체의 동의
																</td>
																<td>동행축제 출석체크 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(제세공과시 필수)</b><br />
																	주민 등록번호(또는 외국인 등록번호), <br />신분증 사본(앞면) <br /><br />
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 휴대전화 번호, 연락처 인증, <br />(해당시)제세공과금 입금확인 관련 입금증,<br />
																	(해당시)경품 수령 및 소득신고 관련 주소
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>3</td>
																<td>2024년 동행축제 ‘찜하기, 키워드’ 이벤트 응모</td>
																<td>정보주체의 동의</td>
																<td>동행축제 찜하기, 키워드 이벤트 응모, 추첨, 본인확인, 문의, 안내사항 전달, 조사 및 통계, 성과 관리 등</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 생년월일, 휴대전화번호, 연락처 인증, 이메일 등
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>4</td>
																<td>2024년 동행축제 ‘찜하기, 키워드’ 이벤트 당첨</td>
																<td>
																	1. 개인정보 보호법 제24조, 24조의2,<br />
																	2. 국세기본법 시행령 제68조제3항,<br />
																	3. 정보주체의 동의
																</td>
																<td>동행축제 찜하기, 키워드 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(제세공과시 필수)</b><br />
																	주민 등록번호(또는 외국인 등록번호), <br />신분증 사본(앞면) <br /><br />
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 휴대전화 번호, 연락처 인증, <br />(해당시)제세공과금 입금확인 관련 입금증,<br />
																	(해당시)경품 수령 및 소득신고 관련 주소
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>5</td>
																<td>2024년 동행축제 언론보도 및 기획기사</td>
																<td>정보주체의 동의</td>
																<td>2024 동행축제 참가기업(약 300개사) 연계 언론보도 및 기획기사 관리</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 전화번호, 사업자번호, 제품정보, 온라인 구매 링크 등
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>6</td>
																<td>2024년 동행축제 참가기업 인플루언서 연계 및 홍보</td>
																<td>정보주체의 동의</td>
																<td>2024 동행축제 참가기업 연계 인플루언서 섭외 및 기업 매칭 연계</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 전화번호, 사업자번호, 제품정보, 온라인 구매 링크 등
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
															<tr>
																<td>7</td>
																<td>2024년 동행축제 동행 에브리데이, 동행내컷 챌린지 이벤트 당첨</td>
																<td>
																	1. 개인정보 보호법 제24조, 24조의2,<br />
																	2. 국세기본법 시행령 제68조제3항,<br />
																	3. 정보주체의 동의
																</td>
																<td>2024년 동행축제 동행 에브리데이, 동행내컷 챌린지 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
																<td>
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(제세공과시 필수)</b><br />
																	주민 등록번호(또는 외국인 등록번호), <br />신분증 사본(앞면) <br /><br />
																	<div class="tooltip-item">
																		<i class="icon-privacy privacy1-1"></i>
																		<em>성명, 생년월일, 전화번호, 주소 등</em>
																	</div>
																	<b>(필수)</b><br />
																	성명, 휴대전화 번호, 연락처 인증, <br />(해당시)제세공과금 입금확인 관련 입금증,<br />
																	(해당시)경품 수령 및 소득신고 관련 주소
																	<br />
																</td>
																<td>
																	수집일로부터 3개월<br />
																	(이벤트 종료, 경품지급 완료시까지)
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</li>
											<li>
												관련 법령에 의해 처리하는 개인정보는 다음과 같습니다.
												<div class="tos-table">
													<table id="tos-table-02">
														<caption>
															관련법령, 보관정보, 처리 및 보유기간 항목의 개인정보 처리 목록
														</caption>
														<colgroup>
															<col style="width: 40%" />
															<col style="width: 40%" />
															<col style="width: 20%" />
														</colgroup>
														<thead>
															<tr>
																<th scope="col">관련법령</th>
																<th scope="col">보관정보</th>
																<th scope="col">처리 및 보유기간</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td rowspan="3">전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조</td>
																<td>계약 또는 청약철회 등에 관한 기록</td>
																<td class="text-center">5년</td>
															</tr>
															<tr>
																<td>대금결제 및 재화 등의 공급에 관한 기록</td>
																<td class="text-center">5년</td>
															</tr>
															<tr>
																<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
																<td class="text-center">3년</td>
															</tr>
															<tr>
																<td>통신비밀보호법 제15조의2 및 동시행령 제41조</td>
																<td>컴퓨터통신 또는 인터넷 접속자료</td>
																<td class="text-center">3개월</td>
															</tr>
														</tbody>
													</table>
												</div>
											</li>
										</ol>
									</li>
								</ol>
							</div>
							<!-- e: 제1조 -->
							<!-- s: 제2조 -->
							<div ref="p2" class="section-policies">
								<h3 class="section-policies-title">
									제2조 개인정보파일 등록현황
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-1"></i>
										<em>개인정보처리항목</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>중소기업유통센터는 개인정보를 소관 업무수행 및 민원처리 등 개인정보처리방침에서 명시한 범위 내에서 처리하고 있으며 「개인정보 보호법」 제32조에 따라 개인정보파일을 공개하고 있습니다. 각 부서에서 운영하는 소관 누리집에도 게재하여 이용자가 확인 할 수 있도록 안내를 하고 있습니다.</li>
								</ol>
							</div>
							<!-- e: 제2조 -->
							<!-- s: 제3조 -->
							<div ref="p3" class="section-policies">
								<h3 class="section-policies-title">
									제3조 개인정보의 제3자 제공
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-6"></i>
										<em>제3자 제공</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>중소기업유통센터는 원칙적으로 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</li>
									<li>
										중소기업유통센터는 원활한 서비스 제공을 위해 필요한 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 제공하고 있으며, 개인정보의 제3자 제공과 관련한 자세한 사항은 소관 누리집에 게재하여 정보주체가 확인할 수 있도록 안내하고 있습니다.
										<div class="tos-table">
											<table id="tos-table-02">
												<caption>
													구분, 제공받는자, 이용목적, 제공항목, 보유기간 항목의 개인정보의 제3자 제공 목록
												</caption>
												<colgroup>
													<col style="width: 15%" />
													<col />
													<col style="width: 30%" />
													<col style="width: 20%" />
												</colgroup>
												<thead>
													<tr>
														<th scope="col">제공받는자</th>
														<th scope="col">제공받는자의 이용목적</th>
														<th scope="col">제공 항목</th>
														<th scope="col">제공받는자의 보유기간</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td rowspan="7">중소벤처기업부</td>
														<td>동행축제 출석체크 이벤트 응모, 추첨, 본인확인, 문의, 안내사항 전달, 조사 및 통계, 성과 관리 등</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />
															성명, 생년월일, 휴대전화번호, 연락처 인증
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>동행축제 출석체크 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 휴대전화번호, 연락처 인증, (해당시)제세공과금 입금확인을 위한 입금증, 경품 수령을 위한 주소 등
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>동행축제 찜하기, 키워드 이벤트 응모, 추첨, 본인확인, 문의, 안내사항 전달, 조사 및 통계, 성과 관리 등</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 생년월일, 휴대전화번호, 연락처 인증
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>동행축제 찜하기, 키워드 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 휴대전화번호, 연락처 인증, (해당시)제세공과금 입금확인을 위한 입금증, 경품 수령을 위한 주소 등
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>2024 동행축제 참가기업(약 300개사) 연계 언론보도 및 기획기사 관리</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 전화번호, 사업자번호, 제품 정보, 온라인 구매링크 등
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>

													<tr>
														<td>2024 동행축제 참가기업 연계 인플루언서 섭외 및 기업 매칭 연계</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 전화번호, 사업자번호, 제품 정보, 온라인 구매링크 등
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>동행 에브리데이, 동행내컷 챌린지 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />성명, 휴대전화번호, 연락처 인증, (해당시)제세공과금 입금확인을 위한 입금증, 경품 수령을 위한 주소 등
														</td>
														<td>
															제공일로부터 3개월<br />
															(제공받는자의 목적 달성시까지)
														</td>
													</tr>
													<tr>
														<td>국세청</td>
														<td>2024 동행축제 이벤트 및 챌린지 경품 및 당첨금 지급 관련 원천징수 및 소득신고</td>
														<td>
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />주민등록번호(또는 외국인등록번호)<br />
															<br />
															<div class="tooltip-item">
																<i class="icon-privacy privacy1-1"></i>
																<em>성명, 생년월일, 전화번호, 주소 등</em>
															</div>
															<br />
															성명, 휴대전화번호, 주소 등
														</td>
														<td>법령에서 규정한 기간</td>
													</tr>
												</tbody>
											</table>
										</div>
									</li>
								</ol>
							</div>
							<!-- e: 제3조 -->
							<!-- s: 제4조 -->
							<div ref="p4" class="section-policies">
								<h3 class="section-policies-title">
									제4조 개인정보처리의 위탁
									<div class="tooltip-item">
										<i class="icon-privacy privacy3-1"></i>
										<em>처리위탁</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										중소기업유통센터는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보처리 업무를 위탁하고 있으며, 개인정보처리 위탁과 관련한 자세한 사항은 소관 누리집에 게재하여 정보주체가 확인할 수 있도록 안내하고 있습니다.
										<div class="tos-table">
											<table>
												<caption>
													위탁 사무명, 위탁하는 업무의 내용, 수탁업체, 담당부서 항목의 개인정보 위탁 목록
												</caption>
												<colgroup>
													<col style="width: 15%" />
													<col style="width: 18%" />
													<col />
													<col style="width: 15%" />
												</colgroup>
												<thead>
													<tr>
														<th scope="col">위탁 사무명</th>
														<th scope="col">수탁업체</th>
														<th scope="col">위탁하는 업무의 내용</th>
														<th scope="col">담당부서</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td rowspan="6">2024년 5월 대한민국 동행축제 운영</td>
														<td>(주)대홍기획</td>
														<td>2024년 동행축제 행사 총괄수행 대행사</td>
														<td rowspan="6">소비촉진 총괄기획팀</td>
													</tr>
													<tr>
														<td>씨리얼팝 주식회사 / (주)스토리잇다</td>
														<td>
															2024년 동행축제 이벤트 수행 대행사<br />
															(출석체크, 찜하기, 키워드 이벤트 및 동행 에브리데이, 동행내컷 챌린지)
														</td>
													</tr>
													<tr>
														<td>(주)디앤애드</td>
														<td>2024년 동행축제 홈페이지 운영유지관리</td>
													</tr>
													<tr>
														<td>(주)PRM</td>
														<td>
															2024년 동행축제 참가 기업<br />
															언론보도 및 기획기사 관리 및 참가 기업 연계<br />
															인플루언서 섭외 및 우수기업 매칭
														</td>
													</tr>
													<tr>
														<td>(주)시온리서치</td>
														<td>2024년 대한민국 동행축제 만족도 설문조사</td>
													</tr>
													<tr>
														<td>나모로지스</td>
														<td>동행축제 출석체크, 찜하기, 키워드 이벤트 및 동행 에브리데이, 동행내컷 챌린지 경품 발송</td>
													</tr>
												</tbody>
											</table>
										</div>
									</li>
									<li>중소기업유통센터는 위탁계약 체결 시, 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</li>
									<li>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.</li>
								</ol>
							</div>
							<!-- e: 제4조 -->
							<!-- s: 제5조 -->
							<div ref="p5" class="section-policies">
								<h3 class="section-policies-title">
									제5조 개인정보의 파기절차 및 방법
									<div class="tooltip-item">
										<i class="icon-privacy privacy3-3"></i>
										<em>파기</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>중소기업유통센터는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
									<li>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.</li>
									<li>
										개인정보 파기의 절차 및 방법은 다음과 같습니다.
										<ol class="number-list">
											<li><strong>파기절차</strong><br />중소기업유통센터는 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여 파기합니다. 중소기업유통센터는 파기 사유가 발생한 개인정보(또는 개인정보파일)를 선정하고, 개인정보보호 책임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기 합니다.</li>
											<li><strong>파기방법</strong><br />중소기업유통센터는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</li>
										</ol>
									</li>
								</ol>
							</div>
							<!-- e: 제5조 -->
							<!-- s: 제6조 -->
							<div ref="p6" class="section-policies">
								<h3 class="section-policies-title">
									제6조 정보주체와 법정대리인의 권리·의무 및 행사방법
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-1"></i>
										<em>정보주체의 권리·의무</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>정보주체는 중소기업유통센터에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>

									<li>권리 행사는 중소기업유통센터에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 중소기업유통센터는 이에 대해 지체없이 조치하겠습니다.</li>

									<li>권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 「개인정보 처리 방법에 관한 고시」 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>

									<li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>

									<li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>

									<li>중소기업유통센터는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>

									<li>중소기업유통센터는 만14세 미만 아동의 개인정보를 수집 또는 이용하는 경우 법정대리인의 법령상의 권리를 보장합니다. 다만, 중소기업유통센터는 현실적으로 법정대리인의 동의를 받기 어려운 만14세 미만 아동의 회원가입은 현재 받고 있지 않습니다.</li>
								</ol>
							</div>
							<!-- e: 제6조 -->
							<!-- s: 제7조 -->
							<div ref="p7" class="section-policies">
								<h3 class="section-policies-title">
									제7조 개인정보의 안전성 확보조치
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-2"></i>
										<em>안전성확보조치</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										중소기업유통센터는 정보주체들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같이 안전성 확보조치를 강구하고 있습니다.
										<ol class="number-list">
											<li><strong>내부관리계획 수립 및 시행</strong><br />내부관리계획 수립 및 시행은 「개인정보의 안전성 확보조치 기준」에 의하여 시행합니다.</li>

											<li><strong>개인정보 취급직원의 최소화 및 교육</strong><br />개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정·관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.</li>

											<li><strong>개인정보에 대한 접근 제한</strong><br />개인정보를처리하는 데이터베이스시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있습니다. 또한, 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 비밀번호의 생성 방법 및 변경 주기 등의 기준을 설정하여 운영하고 있습니다.</li>

											<li><strong>접속기록의 보관 및 점검</strong><br />개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관·관리하고 있으며, 유출·변조·훼손 등에 대응하기 위하여 접속기록 등을 매월 1회 이상 점검하고 있습니다.</li>

											<li><strong>개인정보의 암호화</strong><br />개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>

											<li><strong>보안프로그램 설치 및 주기적 점검·갱신</strong><br />중소기업유통센터는 보안 프로그램을 설치하고 주기적으로 갱신·점검하는 등 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 최선을 다하고 있습니다.</li>

											<li><strong>비인가자에 대한 출입 통제</strong><br />개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</li>
										</ol>
									</li>
								</ol>
							</div>
							<!-- e: 제7조 -->
							<!-- s: 제8조 -->
							<div ref="p8" class="section-policies">
								<h3 class="section-policies-title">
									제8조 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-4"></i>
										<em>자동화 수집</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>중소기업유통센터는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>

									<li>
										쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
										<ol class="number-list">
											<li>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>

											<li>쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.</li>

											<li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
										</ol>
									</li>
								</ol>
							</div>
							<!-- e: 제8조 -->
							<!-- s: 제9조 -->
							<div ref="p9" class="section-policies">
								<h3 class="section-policies-title">
									제9조 추가적인 이용·제공 관련 판단기준
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-3"></i>
										<em>추가적 이용</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										중소기업유통센터는 「개인정보 보호법」 제15조제3항 또는 제17조제4항에 따라 정보주체의 동의 없이 개인정보를 이용 또는 제공하려는 경우에는 다음의 사항을 고려하고 있습니다.
										<ol class="number-list">
											<li>당초 수집 목적과 관련성이 있는지 여부</li>

											<li>개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측이 가능성이 있는지 여부</li>

											<li>정보주체의 이익을 부당하게 침해하는지 여부</li>

											<li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
										</ol>
									</li>
								</ol>
							</div>
							<!-- e: 제9조 -->
							<!-- s: 제10조 -->
							<div ref="p10" class="section-policies">
								<h3 class="section-policies-title">
									제10조 가명정보 처리에 관한 사항
									<div class="tooltip-item">
										<i class="icon-privacy privacy2-5"></i>
										<em>가명정보 처리</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>중소기업유통센터는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 수집한 개인정보를 자체 솔루션 등을 통하여 특정 개인을 알아볼 수 없도록 가명처리할 수 있습니다.</li>
									<li>가명처리 및 가명정보의 제3자 제공이 발생할 경우 그 내용을 개인정보처리방침에 공개할 예정입니다.</li>
								</ol>
							</div>
							<!-- e: 제10조 -->
							<!-- s: 제11조 -->
							<div ref="p11" class="section-policies">
								<h3 class="section-policies-title">
									제11조 개인정보 보호책임자 및 고충사항을 처리하는 부서
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-4"></i>
										<em>개인정보 보호책임자</em>
									</div>
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-5"></i>
										<em>
											고충처리부서<br />
											(담당부서) 정보보안기획팀<br />
											(연락처) 02-6678-9412
										</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										중소기업유통센터는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
										<div class="tos-table">
											<table>
												<caption>
													구분, 부서명, 성명, 연락처, 전자우편, 팩스번호 항목의 개인정보 담당자 목록
												</caption>
												<colgroup>
													<col style="width: 15%" />
													<col style="width: 18%" />
													<col />
													<col style="width: 10%" />
													<col style="width: 13%" />
													<col style="width: 18%" />
													<col style="width: 13%" />
												</colgroup>
												<thead>
													<tr>
														<th colspan="2" scope="col">구분</th>
														<th scope="col">부서명</th>
														<th scope="col">성명</th>
														<th scope="col">연락처</th>
														<th scope="col">전자우편</th>
														<th scope="col">팩스번호</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td rowspan="3">담당 기관 (중소 벤처 기업부)</td>
														<td>개인정보 보호책임자</td>
														<td>정책기획관실 정책기획담당관</td>
														<td>이대건</td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
													<tr>
														<td>개인정보 보호분야별 책임자</td>
														<td>소관부서</td>
														<td>각 부서의 장</td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
													<tr>
														<td>개인정보 보호담당자 (고충사항 처리)</td>
														<td>정보화담당관</td>
														<td>이경훈</td>
														<td>044-204-7377</td>
														<td>joyfriend486@korea.kr</td>
														<td>044-204-7389</td>
													</tr>
													<tr>
														<td rowspan="4">위탁 처리 기관 (중소 기업 유통 센터)</td>
														<td>개인정보 보호책임자</td>
														<td>안전운영지원실장</td>
														<td>이성복</td>
														<td>02-6678-9400</td>
														<td>saltycat@sbdc.or.kr</td>
														<td></td>
													</tr>
													<tr>
														<td>개인정보 보호분야별 책임자</td>
														<td>소관부서</td>
														<td>각 부서의 장</td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
													<tr>
														<td>개인정보 보호담당자</td>
														<td>정보보안파트</td>
														<td>심재경</td>
														<td>02-6678-9411</td>
														<td>spurs@sbdc.or.kr</td>
														<td></td>
													</tr>
													<tr>
														<td>
															개인정보 보호담당자<br />
															(고충사항 처리)
														</td>
														<td>정보보안파트</td>
														<td>박성희</td>
														<td>02-6678-9413</td>
														<td>parksung0910@sbdc.or.kr</td>
														<td>02-6678-9401</td>
													</tr>
												</tbody>
											</table>
										</div>
									</li>
								</ol>
							</div>
							<!-- e: 제11조 -->
							<!-- s: 제12조 -->
							<div ref="p12" class="section-policies">
								<h3 class="section-policies-title">
									제12조 개인정보의 열람청구를 접수·처리하는 부서
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-5"></i>
										<em>
											고충처리부서<br />
											(담당부서) 정보보안기획팀<br />
											(연락처) 02-6678-9412
										</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 중소기업유통센터는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
										<div class="tos-table">
											<table>
												<caption>
													개인정보파일명, 부서명, 담당자, 연락처, 전자우편, 팩스번호 항목의 개인정보 열람청구 부서 목록
												</caption>
												<colgroup>
													<col style="width: 12%" />
													<col />
													<col style="width: 12%" />
													<col style="width: 13%" />
													<col style="width: 18%" />
													<col style="width: 13%" />
												</colgroup>
												<thead>
													<tr>
														<th scope="col">개인정보파일명</th>
														<th scope="col">부서명</th>
														<th scope="col">담당자</th>
														<th scope="col">연락처</th>
														<th scope="col">전자우편</th>
														<th scope="col">팩스번호</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td rowspan="2">가치삽시다</td>
														<td>중소벤처기업부 디지털소상공인과</td>
														<td>조혜지</td>
														<td>044-204-7281</td>
														<td>hya1015@korea.kr</td>
														<td></td>
													</tr>
													<tr>
														<td>중소기업유통센터 정보보안파트</td>
														<td>박성희</td>
														<td>02-6678-9413</td>
														<td>parksung0910@sbdc.or.kr</td>
														<td>02-6678-9401</td>
													</tr>
												</tbody>
											</table>
										</div>
									</li>
									<li>
										정보주체께서는 제1항의 열람청구 접수·처리부서 이외에 ‘개인정보보호 포털’ 웹사이트(www.privacy.go.kr)를 통해 개인정보 열람청구를 하실 수 있습니다.
										<p class="desc-list">청구절차 : 개인정보보호위원회 개인정보보호 포털(www.privacy.go.kr) → 개인서비스 → 정보주체 권리행사 → 개인정보의 열람 등 요구 → 열람 등 요구(본인인증 필요)</p>
									</li>
								</ol>
							</div>
							<!-- e: 제12조 -->
							<!-- s: 제13조 -->
							<div ref="p13" class="section-policies">
								<h3 class="section-policies-title">
									제13조 개인정보의 권익침해에 대한 구제방법
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-6"></i>
										<em>권익침해 구제</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>
										정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 중소기업유통센터와는 별개의 기관으로서, 중소기업유통센터의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
										<ol class="number-list">
											<li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
											<li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
											<li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
											<li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
										</ol>
									</li>
									<li>「개인정보 보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해받은 자는 행정심판법이 정하는 바에 따라 행정 심판을 청구할 수 있습니다.</li>
								</ol>
							</div>
							<!-- e: 제13조 -->
							<!-- s: 제14조 -->
							<div ref="p14" class="section-policies">
								<h3 class="section-policies-title">
									제14조 개인정보처리방침의 변경
									<div class="tooltip-item">
										<i class="icon-privacy privacy4-3"></i>
										<em>처리방침 변경</em>
									</div>
								</h3>
								<ol class="circle-number-list">
									<li>이 개인정보 처리방침은 2024년 4월 30일부터 적용됩니다.</li>
									<li>이전의 개인정보처리방침은 아래에서 확인할 수 있습니다.</li>
								</ol>
							</div>
							<!-- e: 제14조 -->

							<!-- e: 여기까지 -->
						</div>
						<!-- 이력정보 목록 -->
						<div class="board-list-wrapper">
							<div class="board-list-search" :ref="DIV_TABLE">
								<div class="board-list-search__item">
									<select class="form-select form-sm" v-model="srchCondForm.srchOpt">
										<option v-for="(value, key, idx) in SEARCH_OPT" :key="`search_${idx}`" :value="value.code">{{ value.name }}</option>
									</select>
								</div>
								<div class="board-list-search__item header-column">
									<div class="board-list-search__box">
										<label for="" class="blind">검색어입력</label>
										<input type="text" id="" v-model="srchCondForm.srchKeyword" placeholder="검색어를 입력하세요" @keyup.enter="onKeyUpSrch" />
										<button type="button" class="btn btn-primary btn-sm button-search" @click="onClickSrch">
											<span class="text">검색</span>
										</button>
									</div>
								</div>
							</div>

							<div class="board-list-body">
								<table>
									<caption>
										번호, 제목, 다운로드 항목의 목록
									</caption>
									<colgroup>
										<col style="width: 100px" />
										<col />
										<col style="width: 20%" />
									</colgroup>
									<thead>
										<tr>
											<th scope="col">번호</th>
											<th scope="col">제목</th>
											<th scope="col">다운로드</th>
										</tr>
									</thead>
									<tbody v-for="(prvc, idx) in items" :key="idx">
										<tr>
											<td class="hidden-mobile">{{ listNo - idx }}</td>
											<td class="text-left">{{ prvc.prvcPrcsPolicNm }}</td>
											<td>
												<a class="button-default is-primary is-rounded" :disabled="isLoading" @click="onClickDnloadAtch($event, prvc)">다운로드</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<portal-pagination-route page-name="PrivacyPolicy" :total-count="pagingState.totalCount" :page-size="pagingState.pageSize" :page-no="pagingState.pageNo" :query="queries" :parent-method-name="'getPrvcPrcsPolicList'" @getPrvcPrcsPolicList="getPrvcPrcsPolicList"></portal-pagination-route>
						</div>
						<!-- //이력정보 목록 -->
					</div>
				</main>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	ACT_GET_PRVC_PRCS_POLIC_LIST,
	ACT_GET_PRVC_PRCS_POLIC_LATEST,
	// ACT_DOWN_ATTACH_PRVC_PRCS_POLIC,
} from '@/store/_act_consts';

import { getItems, queryToValue, setPaging } from '@/assets/js/utils';

import PageHeader from '@/components/content/PageHeader';
import PortalPaginationRoute from '@/components/PortalPaginationRoute';

export default {
	name: 'PrivacyPolicy',
	components: {
		PageHeader,
		PortalPaginationRoute,
	},
	data: () => ({
		prvcPrcsPolicNm: '',
		prvcPrcsPolicList: [],

		srchCondForm: {
			srchOpt: '',
			srchKeyword: '',
		},
		items: [],
		allicoFile: {
			allicoFilePtUrl: '',
			allicoFileNm: '',
		},
		mngLdgrFile: {
			mngLdgrPtUrl: '',
			mngLdgrFileNm: '',
		},

		pagingState: {},
		isLoading: false,
	}),
	computed: {
		...mapGetters('privacy', ['SEARCH_OPT', 'PRVC_DCD', 'PAGING', 'DIV_TABLE']),

		queries() {
			let result = {};
			result.srchOpt = this.srchCondForm.srchOpt;
			result.srchKeyword = this.srchCondForm.srchKeyword;

			return result;
		},
		allico() {
			return this.allicoFile;
		},
		mngLdgr() {
			return this.mngLdgrFile;
		},
		listNo() {
			return this.pagingState.totalCount - this.pagingState.pageSize * (this.pagingState.pageNo - 1);
		},
	},
	watch: {
		$route(to) {
			this.setSrchCond(to);
			this.getPrvcPrcsPolicList();
		},
	},
	created() {
		this.pagingState = this.PAGING;
		this.setSrchCond(this.$route);
	},
	mounted() {
		this.getPrvcPrcsPolicLatest();
		this.getPrvcPrcsPolicList();
	},
	methods: {
		// event ======================================================================
		onKeyUpSrch() {
			this.getPrvcPrcsPolicList();
		},
		onClickSrch() {
			this.getPrvcPrcsPolicList();
		},
		onClickDnloadAtch(e, prvcObj) {
			this.isLoading = true;

			let link = document.createElement('a');
			try {
				link.href = prvcObj.prvcPrcsPolicPtUrl;
				link.setAttribute('download', prvcObj.prvcPrcsPolicFileNm);
				document.body.appendChild(link);
				link.click();
			} catch (err) {
				console.error(err);
			} finally {
				document.body.removeChild(link);
				this.isLoading = false;
			}
		},

		// custom fn ======================================================================
		scrollTo(id) {
			this.$refs[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		setSrchCond(routeObj) {
			this.srchCondForm.srchOpt = queryToValue(routeObj.query.srchOpt, false, this.SEARCH_OPT['0'].code);
			this.srchCondForm.srchKeyword = queryToValue(routeObj.query.srchKeyword, false, '');
			this.pagingState.pageNo = queryToValue(routeObj.query.pageNo, true, this.PAGING.pageNo);
		},

		// promise ======================================================================
		async getPrvcPrcsPolicList(pageNum = this.pagingState.pageNo) {
			const modelAttr = {
				srchOpt: this.srchCondForm.srchOpt,
				prvcPrcsPolicDcd: this.PRVC_DCD,
				prvcPrcsPolicNm: this.srchCondForm.srchKeyword,
				...this.pagingState,
				pageNo: pageNum,
			};
			this.$store
				.dispatch(`privacy/${ACT_GET_PRVC_PRCS_POLIC_LIST}`, modelAttr)
				.then((res) => {
					if (200 == res.status.code) {
						this.items = getItems(res);
						setPaging(this.pagingState, res);
					} else {
						this.items = [];
						this.pagingState = this.PAGING;
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		async getPrvcPrcsPolicLatest() {
			const modelAttr = {
				prvcPrcsPolicDcd: this.PRVC_DCD,
			};
			this.$store
				.dispatch(`privacy/${ACT_GET_PRVC_PRCS_POLIC_LATEST}`, modelAttr)
				.then((res) => {
					// console.log(res);
					if (200 == res.status.code) {
						this.allicoFile = {
							allicoFilePtUrl: getItems(res)[0].allicoFilePtUrl,
							allicoFileNm: getItems(res)[0].allicoFileNm,
						};
						this.mngLdgrFile = {
							mngLdgrPtUrl: getItems(res)[0].mngLdgrPtUrl,
							mngLdgrFileNm: getItems(res)[0].mngLdgrFileNm,
						};
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
	},
};
</script>
