<template>
	<i class="icon">
		<svg :width="width" :height="height" :viewBox="'0 0 ' + width + ' ' + height" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.6221 1.21704L1.83905 10.0001L10.6221 18.7832" :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	</i>
</template>

<script>
export default {
	props: {
		width: {
			type: Number,
			default: 12,
		},
		height: {
			type: Number,
			default: 20,
		},
		stroke: {
			type: String,
			default: '#424242',
		},
		strokeWidth: {
			type: Number,
			default: 2,
		},
	},
	methods: {},
};
</script>
