<template>
	<div class="page-header-item">
		<button type="button" class="page-header-button" @click.prevent="goBack()">
			<icon-left :width="12" :height="22" stroke="#424242" :stroke-width="2" />
		</button>
		<h2 class="page-header-title">{{ title }}</h2>
		<p v-if="desc !== ''" class="page-header-desc">{{ desc }}</p>
	</div>
</template>

<script>
import IconLeft from '@/components/common/IconLeft.vue';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { mapGetters } from 'vuex';
export default {
	name: 'PageHeader',
	components: { IconLeft },
	props: {
		title: {
			type: String,
			default: '',
		},
		desc: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
	},
	mounted() {},
	data: () => ({}),
	methods: {
		goBack() {
			if (!this.my) {
				// 내 예약 에서만 히스토리백. 그 외에서는 SpaceHome 으로 이동
				let go = -1;
				if (this.prevPath == '/login' && typeof this.session.userId != 'undefined') go = -3;
				this.$router.go(go);
			} else {
				this.$router.push({ name: 'SpaceHome' });
			}
		},
	},
};
</script>
